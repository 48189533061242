export default {
	messages: {
		ru: {
			'Вернуться в личный кабинет': 'Вернуться в личный кабинет',
			'Отчёт об эффективности': 'Отчёт об эффективности'
		},
		uk: {
			'Вернуться в личный кабинет': 'Повернутися в особистий кабінет',
			'Отчёт об эффективности': 'Звіт про ефективність'
		}
	}
};
